// Navigation Bar SECTION
const navBar = {
  show: true,
};

// Main Body SECTION
const mainBody = {
  //gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",

  
  firstName: "John",
  middleName: "",
  lastName: "Langenderfer",
  message: " Software Engineer and Data Scientist. ",
  icons: [
    {
      image: "fa-github",
      url: "https://github.com/johnlangen",
    },
    {
      image: "fa-linkedin",
      url: "https://www.linkedin.com/in/john-langenderfer-/",
    },
  
  ],
};

// ABOUT SECTION
// If you want the About Section to show a profile picture you can fill the profilePictureLink either with:
//a) your Instagram username
//      i.e:profilePictureLink:"johnDoe123",
//b) a link to an hosted image
//      i.e:profilePictureLink:"www.picturesonline.com/johnDoeFancyAvatar.jpg",
//c) image in "editable-stuff" directory and use require("") to import here,
//      i.e: profilePictureLink: require("../editable-stuff/hashirshoaeb.png"),
//d) If you do not want any picture to be displayed, just leave it empty :)
//      i.e: profilePictureLink: "",
// For Resume either provide link to your resume or import from "editable-stuff" directory
//     i.e resume: require("../editable-stuff/resume.pdf"),
//         resume: "https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing",

const about = {
  show: true,
  heading: "About Me",
  imageLink: require("../editable-stuff/resumePhoto.png"),
  imageSize: 375,
  
  message: `My journey as a software engineer and data scientist has been one of continual growth and discovery. Starting with front-end development, I found joy in bringing ideas to life through React and Django projects. To enhance the user experience, I also immersed myself in design, mastering tools like midjourney to create captivating graphics and logos.

  As my career evolved, I delved into back-end technologies like Firebase, and sharpened my skills in creating and managing SQL databases and queries. The world of mobile and desktop app development soon beckoned, leading me to explore Flutter. Today, I'm actively engaged in building an app and desktop version using this dynamic framework, connecting it to the back-end through Node.js middleware.

  While these developments were exciting, my true passion has always been data. My fascination began in college with basic concepts like linear and logistic regressions, which paved the way for a deeper understanding of data architecture. As my knowledge expanded, I ventured into more complex territories like Natural Language Processing, where I learnt about token predictors and transformers, and Machine Learning, where I studied neural networks.

  My academic journey was further enriched by a course in Computer Vision, which opened up the world of convolutional neural networks. The theoretical knowledge gained from these advanced classes found practical application in various projects. These range from fun ones involving finance and sports to more academic ventures like stock predictions using LSTM and skin cancer detection using convolutional neural networks. Each project was a lesson in choosing the right hyperparameters, fine-tuning models, interpreting data, and staying updated with the latest AI tools and news.

  I've always been drawn to the ethical side of AI, and recently had the opportunity to delve deeper into this interest by submitting a paper for a Kaggle competition on AI ethics. This experience cemented my desire to contribute to this field and ensure that AI is developed and used responsibly. I'm also intrigued by Artificial General Intelligence and the possibility of creating multimodal models. The idea of recreating the human experience through technology fascinates me, and I believe it's our responsibility to steer this technology in a way that benefits the world before we reach the singularity of AI.`
,
  
  resume: require("../editable-stuff/resume.pdf"),
};

const projectsSection = {
  show: true,
  heading: "Featured Projects",
  projects: [
    {
      title: "Project 1",
      description: "Description of Project 1",
      pdfLink: "link-to-pdf-file-1",
    },
    {
      title: "Project 2",
      description: "Description of Project 2",
      pdfLink: "link-to-pdf-file-2",
    },
  ],
};


// PROJECTS SECTION
// Setting up project lenght will automatically fetch your that number of recently updated projects, or you can set this field 0 to show none.
//      i.e: reposLength: 0,
// If you want to display specfic projects, add the repository names,
//      i.e ["repository-1", "repo-2"]
const repos = {
  show: false,
  heading: "Recent Projects",
  gitHubUsername: "johnlangen", // i.e. "johnDoe12Gh"
  reposLength: 0, // Set this to 0 to display specific repositories
  specificRepos: [
    "StockPredictionsWithML",
    "BiomedicalImageAnalysis",
    "RandomNumberWebsite",
    "PowerPlayerWebsite",
    "Financial-Modeling-Programs",
    "Personal--Website",
  ],
};

// Leadership SECTION
const leadership = {
  show: false,
  heading: "Leadership",
  message:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Vitae auctor eu augue ut lectus arcu bibendum at varius. Libero justo laoreet sit amet cursus sit amet. Imperdiet dui accumsan sit amet nulla facilisi morbi. At auctor urna nunc id. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Et magnis dis parturient montes nascetur ridiculus mus mauris. In nisl nisi scelerisque eu ultrices vitae auctor. Mattis nunc sed blandit libero volutpat sed cras ornare. Pulvinar neque laoreet suspendisse interdum consectetur libero.",
  images: [
    { 
      img: require("../editable-stuff/resumePhoto.png"), 
      label: "First slide label", 
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
    },
    { 
      img: require("../editable-stuff/resumePhoto.png"), 
      label: "Second slide label", 
      paragraph: "Nulla vitae elit libero, a pharetra augue mollis interdum." 
    },
  ],
  imageSize: {
    width:"615",
    height:"450"
  }
};

// SKILLS SECTION
const skills = {
  show: true,
  heading: "Skills",
  hardSkills: [
    { name: "Python", value: 95},
    { name: "SQL", value: 80 },
    { name: "Django", value: 90 },
    { name: "Firebase", value: 85 },
    { name: "JavaScript", value: 90 },
    { name: "React", value: 80 },
    { name: "HTML/CSS", value: 95 },
    { name: "Flutter", value: 90 },
  ],
  softSkills: [
    { name: "Goal-Oriented", value: 95 },
    { name: "Collaboration", value: 95 },
    { name: "Positivity", value: 100 },
    { name: "Adaptability", value: 90 },
    { name: "Problem Solving", value: 100 },
    { name: "Empathy", value: 85 },
    { name: "Organization", value: 90 },
    { name: "Creativity", value: 90 },
  ],
};

// GET IN TOUCH SECTION
const getInTouch = {
  show: true,
  heading: "Get In Touch",
  message:
    "I'm currently looking for a Software Engineering or Data Science job! If you know of any positions available, if you have any questions, or if you just want to say hi, please feel free to email me at",
  email: "langen.p.john@gmail.com",
};

const experiences = {
  show: false,
  heading: "Experiences",
  data: [
    {
      role: 'Software Engineer',// Here Add Company Name
      companylogo: require('../assets/img/dell.png'),
      date: 'June 2018 – Present',
    },
    {
      role: 'Front-End Developer',
      companylogo: require('../assets/img/boeing.png'),
      date: 'May 2017 – May 2018',
    },
  ]
}

// Blog SECTION
// const blog = {
//   show: false,
// };

export { navBar, mainBody, about, repos, skills, leadership, getInTouch, experiences, projectsSection };

